<template>
  <ServicesDescription
    :buttonText="buttonText"
    :isButtonIcon="isIcon"
    @connectService="getDocument()"
    :isFooterBtnLeft="isFooterBtnLeft"
  >
    <template #body>
      <p v-for="row in text" :key="row.id">
        {{ row.text }}
      </p>
      <p>
        <strong>
          Выберите бонусы, которые хотите получить (можно выбрать несколько вариантов):
        </strong>
      </p>
      <!-- Выбор бонуса -->
      <div
        v-for="bonus in bonusesTypes"
        :key="bonus.id"
        :class="$style.bonusItem"
        class="dflex_nowrap"
      >
        <base-checkbox
          v-model="bonus.value"
          :value="bonus.value"
          :id="bonus.id"
          :checked="bonusesTypes.value"
        ></base-checkbox>
        <label :for="bonus.id">{{ bonus.label }}</label>
      </div>
      <br />
      <!-- Ввод данных заявителя -->
      <b-row v-for="user in userData" :key="user.id">
        <b-col md="5" lg="5" xl="4">
          <b-form-group :label="user.label">
            <BaseTextInput
              v-if="user.id === 'u1'"
              v-model="user.value"
              :placeholder="user.placeholder"
            />
            <InputPhone
              v-else
              v-model="user.value"
              :placeholder="user.placeholder"
           />
          </b-form-group>
        </b-col>
      </b-row>
    </template>
  </ServicesDescription>
</template>

<script>
import InputPhone from '@/components/shared/inputs/InputPhone.vue';
import BaseCheckbox from '@/components/shared/inputs/BaseCheckbox.vue';
import BaseTextInput from '@/components/shared/inputs/BaseTextInput.vue';
import ServicesDescription from '@/components/shared/ServicesDescription.vue';

export default {
  // импортируется из ../StatementsCreation.vue
  inject: ['createDocument', 'isFooterBtnLeft', 'isIcon', 'buttonText'],

  data() {
    return {
      statementDescription: 'Программа лояльности "Приведи друга"',
      text: [
        {
          id: 1,
          text: 'Компания «Онлайн Лоджик» благодарит Вас за сотрудничество!'
        },
        {
          id: 2,
          text:
            'Предлагаем рассмотреть персональное приглашение к участию в программе лояльности "Приведи друга".'
        },
        {
          id: 3,
          text:
            'Мы предлагаем ряд бонусов, который Вы можете получить за то, что дадите рекомендацию нас как поставщика услуг связи Вашим клиентам, партнерам, соседям по офису.'
        }
      ],
      bonusesTypes: [
        {
          id: 'b1',
          value: false,
          key: 'IsSpeedIncreaseEnabled',
          label:
            'Увеличение скорости доступа в сеть Интернет в 2 раза (но не более чем на 10 Мб) при наличии технической возможности'
        },
        {
          id: 'b2',
          value: false,
          key: 'IsStaticIpEnabled',
          label: 'Дополнительный статический IP - адрес без оплат'
        },
        {
          id: 'b3',
          value: false,
          key: 'IsFreePhoneNumberWithVirtualOfficeEnabled',
          label: 'При подключении услуг «Виртуальная АТС» и «Виртуальный офис» - номер без оплат'
        },
        {
          id: 'b4',
          value: false,
          key: 'IsBitrix24IntegrationEnabled',
          label: 'Интеграция «Виртуальной АТС» с CRM Битрикс 24 без абонентской платы'
        },
        {
          id: 'b5',
          value: false,
          key: 'IsAdditionalFreePhoneNumberEnabled',
          label: 'Дополнительный номер без оплат'
        },
        {
          id: 'b6',
          value: false,
          key: 'IsFreeVoiceRecordingEnabled',
          label:
            'Запись разговоров для 1 линии - без абонентской платы (при подключении сервиса «Запись разговоров» от 2-х линий)'
        },
        {
          id: 'b7',
          value: false,
          key: 'IsSpecialTariffEnabled',
          label:
            'Специальный тариф на междугородние звонки: Москва, Санкт-Петербург, Екатеринбург - по 1 руб./мин'
        }
      ],
      userData: [
        {
          id: 'u1',
          value: '',
          placeholder: 'ФИО ответственного лица',
          label: 'Имя и отчество: '
        },
        {
          id: 'u2',
          value: '',
          placeholder: 'Телефон ответственного лица',
          label: 'Контактный номер телефона: '
        }
      ]
    };
  },

  components: {
    BaseCheckbox,
    BaseTextInput,
    InputPhone,
    ServicesDescription
  },

  computed: {
    isValid() {
      const [userName, userPhone] = this.userData;
      const isBonuses = this.bonusesTypes.some((el) => el.value === true);
      return (
        userName.value
        && userName.value.length > 2
        && userPhone.value
        && userPhone.value.length > 17
        && isBonuses
      );
    },

    userDataToSend() {
      const bonusesEnabled = this.bonusesTypes.filter((el) => el.value === true);
      const bonusesToSend = bonusesEnabled.map((el) => [el.key, el.value]);
      const [userName, userPhone] = this.userData;
      return {
        InviteBonus: Object.fromEntries(bonusesToSend),
        ResponsiblePersonFullName: userName.value,
        ResponsiblePersonPhone: userPhone.value
      };
    }
  },

  methods: {
    getDocument() {
      this.createDocument(this.userDataToSend, this.isValid);
    }
  }
};
</script>

<style module>
.bonusItem:not(.bonusItem:first-child) {
  padding-top: 1rem;
}

@media (min-width: 992px) {
  .bonusItem:not(.bonusItem:first-child) {
    padding-top: 0;
  }
}
</style>
